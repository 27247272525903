import { gsap } from 'gsap';
import * as emitter from 'tiny-emitter/instance';

import { lerp } from '../../utils/math';

export default class Zoom {
  constructor() {
    this.el = document.querySelector('.a-zoom');

    this.image = this.el.querySelector('.image');

    this.mouse = {
      last: 0,
      current: 0
    }

    this.ease = 0.05;
    this.visible = false;
  }

  mounted() {
    emitter.on('zoom: load', this.load);
    emitter.on('zoom: enter', this.enter);
    emitter.on('zoom: leave', this.leave);

    this.addEvents();
  }

  addEvents = () => {
    this.el.addEventListener('click', this.leave);
    this.el.addEventListener('mousemove', this.onMouseMove);
  }

  load = (src) => {
    this.image.innerHTML = '';
    this.img = document.createElement('img');
    this.img.src = src;
    this.image.appendChild(this.img);
  }

  enter = () => {
    if (this.visible) return;

    gsap.fromTo(
      this.el,
      { autoAlpha: 0 },
      { autoAlpha: 1 }
    );

    this.img.onload = () => {
      this.onResize();
    }

    gsap.ticker.add(this.onRaf);

    this.visible = true;
  }

  leave = () => {
    if (!this.visible) return;

    gsap.fromTo(
      this.el,
      { autoAlpha: 1 },
      { autoAlpha: 0 }
    );

    gsap.ticker.remove(this.onRaf);

    this.visible = false;
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;
    this.panHeight = this.image.clientHeight - this.windowHeight;
  }

  onRaf = () => {
    this.mouse.last = lerp(this.mouse.last, -this.mouse.current, this.ease);
    this.img.style.transform = `translate(0, ${this.mouse.last}px)`;
  }

  onMouseMove = (e) => {
    this.mouse.current = (e.clientY / this.windowHeight - 0.5) * this.panHeight;
  }
}
