import { gsap } from 'gsap';
import * as emitter from 'tiny-emitter/instance';

export default class HeaderDecor {
  constructor() {
    this.el = document.querySelector('.s-structure');

    this.image = this.el.querySelector('.image');
    this.img = this.el.querySelectorAll('.img');

    // this.switch = this.el.querySelector('.switch');
    // this.sphere = this.el.querySelector('.sphere');
    // this.icon = this.el.querySelectorAll('.icon');
    // this.view = this.el.querySelector('.view');

    // TODO: set to 0 if have product image
    this.toggle = 1;
  }

  mounted() {
    this.addEvents();
  }

  destroyed = () => {
    this.removeEvents();
  }

  addEvents = () => {
    this.image.addEventListener('click', this.onZoom);
    // this.switch.addEventListener('click', this.onToggle);
  }

  removeEvents = () => {
    this.image.removeEventListener('click', this.onZoom);
    // this.switch.addEventListener('click', this.onToggle);
  }

  getImage = () => {
    let src;

    if (this.toggle == 0) {
      src = this.image.dataset.product;
    } else {
      src = this.image.dataset.surface;
    }

    emitter.emit('zoom: load', src);
  }

  onZoom = () => {
    this.getImage();

    emitter.emit('zoom: enter');
  }

  onToggle = () => {
    if (this.toggle == 0) {
      this.toggle = 1;
      this.isSurface();
    } else {
      this.toggle = 0;
      this.isProduct();
    }
  }

  isSurface = () => {
    gsap.fromTo(
      [this.img[1], this.icon[1]],
      { autoAlpha: 0 },
      { autoAlpha: 1 }
    );

    gsap.fromTo(
      this.sphere,
      { xPercent: 0 },
      { xPercent: -150 }
    );

    this.view.innerHTML = this.view.dataset.surface;
  }

  isProduct = () => {
    gsap.fromTo(
      [this.img[1], this.icon[1]],
      { autoAlpha: 1 },
      { autoAlpha: 0 }
    );

    gsap.fromTo(
      this.sphere,
      { xPercent: -150 },
      { xPercent: 0 }
    );

    this.view.innerHTML = this.view.dataset.product;
  }
}
