import { gsap } from 'gsap';

import * as emitter from 'tiny-emitter/instance';

export default class Menu {
  constructor() {
    this.el = document.querySelector('.a-menu');
  }

  mounted() {
    emitter.on('menu: enter', this.enter);
    emitter.on('menu: leave', this.leave);
    emitter.on('menu: toggle', this.toggle);

    this.el.addEventListener('click', this.toggle);
  }

  toggle = () => {
    if (this.active) {
      this.active = false;
      this.el.innerHTML = 'Menu';
      emitter.emit('nav: leaveNav');
    } else {
      this.active = true;
      this.el.innerHTML = 'Close';
      emitter.emit('nav: enterNav');
    }
  }

  enter = () => {
    gsap.to(this.el, {
      autoAlpha: 1
    });
  }

  leave = () => {
    gsap.to(this.el, {
      autoAlpha: 0
    });
  }
}
