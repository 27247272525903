import { gsap } from 'gsap';
import * as emitter from 'tiny-emitter/instance';

export default class NavSurfaces {
  constructor() { }

  mounted() {
    this.el = document.querySelector('.a-nav-surfaces');

    this.links = this.el.querySelectorAll('.link');

    emitter.on('navSurfaces: init', this.init);

    this.visible = false;

    this.init();
  }

  init = () => {
    this.setActive();
    this.setVisibility();
  }

  setVisibility = () => {
    if (
      document.querySelector('.p-collection') ||
      document.querySelector('.p-kollektion')
    ) {
      if (!this.visible) {
        this.enter();
        this.visible = true;
      }
    } else {
      if (this.visible) {
        this.leave();
        this.visible = false;
      }
    }
  }

  setActive = () => {
    this.links.forEach((el) => {
      el.parentElement.classList.remove('is-active');

      if (el.href === location.href) {
        el.parentElement.classList.add('is-active');
      }
    });
  }

  enter = () => {
    gsap.fromTo(
      this.el,
      { autoAlpha: 0 },
      { autoAlpha: 1 }
    )
  }

  leave = () => {
    gsap.fromTo(
      this.el,
      { autoAlpha: 1 },
      { autoAlpha: 0 }
    )
  }
}
