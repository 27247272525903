import { gsap } from 'gsap';
import { install } from 'ga-gtag';

export default class Notification {
  constructor() {
    this.el = document.querySelector('.a-notification');

    this.accept = this.el.querySelector('.accept');
    this.decline = this.el.querySelector('.decline');

    this.cookie = false;
  }

  mounted() {
    this.accept.addEventListener('click', this.enable);
    this.decline.addEventListener('click', this.disable);

    if (document.cookie.match(/^(.*;)?\s*_ga\s*=\s*[^;]+(.*)?$/)) {
      this.cookie = true;
      this.enable();
    } else {
      this.enter();
    }
  }

  enable = () => {
    install('G-C36CPP6FPW');

    if (this.cookie) {
      this.el.remove(); return;
    };

    this.leave();
  }

  disable = () => {
    this.leave();
  }

  enter = () => {
    gsap.fromTo(
      this.el,
      { autoAlpha: 0 },
      { autoAlpha: 1 }
    )
  }

  leave = () => {
    gsap.fromTo(
      this.el,
      { autoAlpha: 1 },
      { autoAlpha: 0, onComplete: () => { this.el.remove() } }
    )
  }
}
