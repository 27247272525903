import { gsap } from 'gsap';
import Sniffer from 'sniffer';

import { zeroPad } from '../../utils/math';

export default class Slider {
  constructor() {
    this.el = document.querySelector('.s-slider');

    this.rotate = this.el.querySelector('.rotate');
    this.mobile = this.el.querySelector('.images-mobile');
    this.desktop = this.el.querySelector('.images-desktop');

    this.index = 1;
  }

  mounted() {
    if (Sniffer.isPhone) {
      this.desktop.remove();
    } else {
      this.mobile.remove();
    }

    this.init();

    this.setIndicator();
    this.setImage();

    this.addEvents();
  }

  destroyed = () => {
    this.removeEvents();
  }

  init = () => {
    this.total = this.el.querySelector('.total');
    this.image = this.el.querySelectorAll('.image');
    this.current = this.el.querySelector('.current');
  }

  setIndicator = () => {
    this.current.innerHTML = zeroPad(this.index, 2);
    this.total.innerHTML = zeroPad(this.image.length, 2);
  }

  setImage = () => {
    gsap.set(this.image, { autoAlpha: 0 });
    gsap.set(this.image[this.index - 1], { autoAlpha: 1 });
  }

  addEvents = () => {
    this.el.addEventListener('click', this.setSlide);
  }

  removeEvents = () => {
    this.el.removeEventListener('click', this.setSlide);
  }

  setSlide = () => {
    gsap.set(this.image, { zIndex: 1 });
    gsap.set(this.image[this.index - 1], { zIndex: 2 });

    this.index === this.image.length ? this.index = 1 : this.index++;

    this.setIndicator();

    gsap.fromTo(
      this.image[this.index - 1],
      { autoAlpha: 0, zIndex: 3, scale: 1.2 },
      { autoAlpha: 1, scale: 1, duration: 2, ease: 'expo.out' }
    );

    gsap.fromTo(
      this.rotate,
      { rotate: 0 },
      { rotate: -180 }
    );
  }
}
