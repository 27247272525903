// highway
import Highway from '@dogstudio/highway';

// components
import Components from '../components/index';

class Renderer extends Highway.Renderer {
  onEnter() { }
  onLeave() { }
  onEnterCompleted() {
    this.components = new Components();
    this.components.mounted();
  }
  onLeaveCompleted() {
    this.components.destroyed();
    this.components = undefined;
  }
}

// export
export default Renderer;
