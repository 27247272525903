import { gsap } from 'gsap';
import Sniffer from 'sniffer';
import * as emitter from 'tiny-emitter/instance';

import { lerp } from '../../utils/math';

export default class Cursor {
  constructor() {
    this.el = document.querySelector('.a-cursor');

    this.container = this.el.querySelector('.container');

    this.rotate = this.el.querySelector('.rotate');
    this.shrink = this.el.querySelector('.shrink');
    this.scale = this.el.querySelector('.scale');

    this.target = [0, 0];
    this.current = [0, 0];
  }

  mounted() {
    if (Sniffer.isDevice) return;

    gsap.ticker.add(this.onRaf);

    window.addEventListener('mousemove', this.onMouseMove, false);

    emitter.on('cursor: init', this.init);

    this.init();
  }

  init = () => {
    const cursor = document.querySelectorAll('[data-cursor]');
    const scale = document.querySelectorAll('[data-cursor="scale"]');
    const shrink = document.querySelector('[data-cursor="shrink"]');
    const slider = document.querySelector('[data-cursor="slider"]');

    this.leave(this.container);

    if (cursor) {
      cursor.forEach((el) => {
        el.addEventListener('mouseenter', () => {
          this.enter(this.container);
        });
        el.addEventListener('mouseleave', () => {
          this.leave(this.container);
        });
      });
    }

    if (scale) {
      scale.forEach((el) => {
        el.addEventListener('mouseenter', () => {
          this.enter(this.scale);
        });
        el.addEventListener('mouseleave', () => {
          this.leave(this.scale);
        });
      });
    }

    if (shrink) {
      shrink.addEventListener('mouseenter', () => {
        this.enter(this.shrink);
      });
      shrink.addEventListener('mouseleave', () => {
        this.leave(this.shrink);
      });
    }

    if (slider) {
      slider.addEventListener('mouseenter', () => {
        this.enter(this.rotate);
      });
      slider.addEventListener('mouseleave', () => {
        this.leave(this.rotate);
      });
      slider.addEventListener('click', () => {
        gsap.fromTo(
          this.rotate,
          { rotate: 0 },
          { rotate: -180 }
        );
      });
    }
  }

  onMouseMove = ({ clientX: x, clientY: y }) => {
    this.target = [x, y];
  }

  onRaf = () => {
    this.current = [
      lerp(this.current[0], this.target[0], 0.1),
      lerp(this.current[1], this.target[1], 0.1)
    ];

    this.el.style.transform = `
      translate3d(
        ${this.current[0]}px,
        ${this.current[1]}px,
        0
      )
    `;
  }

  enter = (el) => {
    gsap.to(
      el,
      { autoAlpha: 1 }
    );
  }

  leave = (el) => {
    gsap.to(
      el,
      { autoAlpha: 0 }
    );
  }
}
