import * as emitter from 'tiny-emitter/instance';

export default class Footer {
  constructor() {
    this.el = document.querySelector('.p-footer');

    this.scroll = this.el.querySelector('.scroll');
  }

  mounted() {
    this.addEvents();
  }

  destroyed = () => {
    this.removeEvents();
  }

  addEvents = () => {
    this.scroll.addEventListener('click', this.scrollTop);
  }

  removeEvents = () => {
    this.scroll.removeEventListener('click', this.scrollTop);
  }

  scrollTop = () => {
    emitter.emit('scroll: scrollTop');
  }
}
