import { gsap } from 'gsap';
import Sniffer from 'sniffer';
import VirtualScroll from 'virtual-scroll';
import * as emitter from 'tiny-emitter/instance';

import { lerp } from './math';

export default class Scroll {
  constructor() {
    this.el = document.querySelector('[data-scroll]');

    const options = {
      mouseMultiplier: 0.8,
    }

    this.vs = new VirtualScroll(options);

    this.last = 0;
    this.ease = 0.1;
    this.current = 0;

    this.top = false;
    this.direction = 0;
  }

  mounted() {
    emitter.on('scroll: scrollTop', this.scrollTop);
    emitter.on('scroll: destroyed', this.destroyed);

    gsap.ticker.add(this.onRaf);

    if (Sniffer.isDevice) {
      window.addEventListener('scroll', this.onScroll);

      return;
    }

    this.vs.on(this.onScroll);

    window.addEventListener('resize', this.onResize, { passive: true });

    this.onResize();

    gsap.delayedCall(1, this.onResize);
  }

  destroyed = () => {
    emitter.off('scroll: scrollTop', this.scrollTop);
    emitter.off('scroll: destroyed', this.destroyed);

    gsap.ticker.remove(this.onRaf);

    if (Sniffer.isDevice) {
      window.removeEventListener('scroll', this.onScroll);

      return;
    }

    this.vs.destroy();

    window.removeEventListener('resize', this.onResize, { passive: true });
  }

  onRaf = () => {
    this.checkTop();
    this.checkDirection();

    if (Sniffer.isDevice) return;

    if (-this.last < 0.1) this.last = 0;

    this.last = lerp(this.last, this.current, this.ease);
    this.el.style.transform = `translate3d(0, ${this.last}px, 0)`;
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;

    this.bounding = this.el.getBoundingClientRect();
  }

  onScroll = (e) => {
    if (Sniffer.isDevice) {
      this.current = -window.scrollY;
    } else {
      this.current += e.deltaY;
    }

    if (Sniffer.isDevice) return;

    this.current = Math.max(-(this.bounding.height - this.windowHeight), this.current);
    this.current = Math.min(0, this.current);
  }

  scrollTop = () => {
    if (Sniffer.isDevice) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.current = 0;
    }
  }

  checkTop = () => {
    if (-this.current > 0 && !this.top) {
      emitter.emit('nav: enterFill');
      this.top = true;
    }

    if (-this.current === 0 && this.top) {
      emitter.emit('nav: leaveFill');
      this.top = false;
    }
  }

  checkDirection = () => {
    if (this.last > this.current && !this.direction) {
      this.direction = 1;

      emitter.emit('logo: leave');

      if (Sniffer.isPhone) {
        emitter.emit('menu: leave');
        emitter.emit('nav: leaveFill');
      } else {
        emitter.emit('nav: leaveEl');
      }
    }

    if (this.last < this.current && this.direction) {
      this.direction = 0;

      emitter.emit('logo: enter');

      if (Sniffer.isPhone) {
        emitter.emit('menu: enter');
        emitter.emit('nav: enterFill');
      } else {
        emitter.emit('nav: enterEl');
      }
    }

    if (Sniffer.isDevice) {
      this.last = this.current;
    }
  }
}
