import { gsap } from 'gsap';
import Highway from '@dogstudio/highway';

class Default extends Highway.Transition {
  in({ from, to, done }) {
    from.remove();

    window.scrollTo(0, 0);

    gsap.fromTo(
      to,
      { autoAlpha: 0 },
      { autoAlpha: 1 }
    );

    done();
  }

  out({ from, done }) {
    gsap.fromTo(
      from,
      { autoAlpha: 1 },
      { autoAlpha: 0, onComplete: done }
    );
  }
}

export default Default;
