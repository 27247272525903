import { gsap } from 'gsap';
import sniffer from 'sniffer';

import * as emitter from 'tiny-emitter/instance';

export default class Nav {
  constructor() {
    this.el = document.querySelector('.a-nav');

    this.bar = this.el.querySelector('.bar');
    this.link = this.el.querySelectorAll('a');
    this.navigation = this.el.querySelector('.navigation');
  }

  mounted() {
    emitter.on('nav: enterEl', this.enterEl);
    emitter.on('nav: leaveEl', this.leaveEl);

    emitter.on('nav: enterNav', this.enterNav);
    emitter.on('nav: leaveNav', this.leaveNav);

    emitter.on('nav: enterFill', this.enterFill);
    emitter.on('nav: leaveFill', this.leaveFill);

    if (sniffer.isPhone) {
      this.addEvents();
    }
  }

  addEvents = () => {
    this.link.forEach(link => link.addEventListener('click', this.emitMenu));
  }

  emitMenu = () => {
    emitter.emit('menu: toggle');
  }

  enterEl = () => {
    gsap.to(this.el, {
      autoAlpha: 1
    });
  }

  leaveEl = () => {
    gsap.to(this.el, {
      autoAlpha: 0
    });
  }

  enterNav = () => {
    document.body.classList.add('is-nav');

    gsap.to(this.navigation, {
      autoAlpha: 1
    });
  }

  leaveNav = () => {
    document.body.classList.remove('is-nav');

    gsap.to(this.navigation, {
      autoAlpha: 0
    });
  }

  enterFill = () => {
    document.body.classList.add('is-fill');
  }

  leaveFill = () => {
    document.body.classList.remove('is-fill');
  }
}
