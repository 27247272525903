import * as emitter from 'tiny-emitter/instance';

export default class HeaderDecor {
  constructor() {
    this.el = document.querySelector('.p-header-decor');

    this.image = this.el.querySelector('.image');
  }

  mounted() {
    this.addEvents();
  }

  destroyed = () => {
    this.removeEvents();
  }

  addEvents = () => {
    this.image.addEventListener('click', this.onZoom);
  }

  removeEvents = () => {
    this.image.removeEventListener('click', this.onZoom);
  }

  getImage = () => {
    const src = this.image.dataset.image;

    emitter.emit('zoom: load', src);
  }

  onZoom = () => {
    this.getImage();

    emitter.emit('zoom: enter');
  }
}
