export default class Decor {
  constructor() {
    this.posts = [];
  }

  mounted() {
    async function fetchPosts() {
      try {
        const response = await fetch('/wp-json/wp/v2/decor?acf_format=standard&per_page=100&lang=en');

        const posts = await response.json();

        return posts;
      } catch (e) {}
    }

    fetchPosts().then(posts => {
      this.posts = posts;
    });
  }
}
