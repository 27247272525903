// store
import { app } from '../utils/store';

// components
import Banner from '../components/banner/banner';
import Collection from '../components/collection/collection';
import Footer from '../components/footer/footer';
import HeaderDecor from '../components/header-decor/header-decor';
import Hero from '../components/hero/hero';
import NavDecor from '../components/nav-decor/nav-decor';
import Slider from '../components/slider/slider';
import Structure from '../components/structure/structure';
import Surfaces from '../components/surfaces/surfaces';

// ui
import Hud from '../utils/hud';
import Scroll from '../utils/scroll';

// export
export default class Components {
  constructor() {
    this.banner = document.querySelector('.s-banner');
    this.collection = document.querySelector('.s-collection');
    this.footer = document.querySelector('.p-footer');
    this.headerDecor = document.querySelector('.p-header-decor');
    this.hero = document.querySelector('.p-hero');
    this.navDecor = document.querySelector('.p-nav-decor');
    this.slider = document.querySelector('.s-slider');
    this.structure = document.querySelector('.s-structure');
    this.surfaces = document.querySelector('.s-surfaces');
  }

  destroyed = () => {
    app.hud.destroyed();
    app.scroll.destroyed();

    if (this.banner) { this.banner.destroyed(); }
    if (this.collection) { this.collection.destroyed(); }
    if (this.footer) { this.footer.destroyed(); }
    if (this.headerDecor) { this.headerDecor.destroyed(); }
    if (this.hero) { this.banner.destroyed(); }
    if (this.navDecor) { this.navDecor.destroyed(); }
    if (this.slider) { this.slider.destroyed(); }
    if (this.structure) { this.structure.destroyed(); }
    if (this.surfaces) { this.surfaces.destroyed(); }
  }

  mounted = () => {
    app.hud = new Hud();
    app.hud.mounted();

    app.scroll = new Scroll();
    app.scroll.mounted();

    if (this.banner) {
      this.banner = new Banner();
      this.banner.mounted();
    }

    if (this.collection) {
      this.collection = new Collection();
      this.collection.mounted();
    }

    if (this.footer) {
      this.footer = new Footer();
      this.footer.mounted();
    }

    if (this.headerDecor) {
      this.headerDecor = new HeaderDecor();
      this.headerDecor.mounted();
    }

    if (this.hero) {
      this.hero = new Hero();
      this.hero.mounted();
    }

    if (this.navDecor) {
      this.navDecor = new NavDecor();
      this.navDecor.mounted();
    }

    if (this.slider) {
      this.slider = new Slider();
      this.slider.mounted();
    }

    if (this.structure) {
      this.structure = new Structure();
      this.structure.mounted();
    }

    if (this.surfaces) {
      this.surfaces = new Surfaces();
      this.surfaces.mounted();
    }
  }
}