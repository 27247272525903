import { gsap } from 'gsap';

import * as emitter from 'tiny-emitter/instance';

export default class Logo {
  constructor() {
    this.el = document.querySelector('.a-logo');
  }

  mounted() {
    emitter.on('logo: enter', this.enter);
    emitter.on('logo: leave', this.leave);
  }

  enter = () => {
    gsap.to(this.el, {
      autoAlpha: 1
    });
  }

  leave = () => {
    gsap.to(this.el, {
      autoAlpha: 0
    });
  }
}
