export default class Hud {
  constructor() {
    this.nav = document.querySelector('.a-nav');
  }

  mounted() {
    this.getHud();
    this.setHud();
  }

  destroyed() {
    this.light = undefined;
  }

  getHud() {
    this.light = document.querySelector('[data-light]');
  }

  setHud = () => {
    if (this.light) {
      document.body.classList.add('is-light');
    } else {
      document.body.classList.remove('is-light');
    }
  }
}
