import { gsap } from 'gsap';

export default class NavDecor {
  constructor() {
    this.el = document.querySelector('.p-nav-decor');

    this.content = this.el.querySelector('.content').children;
    this.item = this.el.querySelectorAll('.item');
  }

  mounted() {
    this.openContent(0, 0);

    this.item.forEach((el, i) => {
      el.addEventListener('click', () => {
        this.openContent(el, i);
      });
    });
  }

  destroyed = () => { }

  openContent = (el, i) => {
    gsap.set(this.content, { display: 'none' });
    gsap.set(this.content[i], { display: 'flex' });

    this.item.forEach((el) => {
      el.classList.remove('is-active');
    });

    this.item[i].classList.add('is-active');
  }
}
