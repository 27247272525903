import gsap from 'gsap';
import Sniffer from 'sniffer';
import VirtualScroll from 'virtual-scroll';
import * as emitter from 'tiny-emitter/instance';

import { lerp } from '../../utils/math';

export default class Collection {
  constructor() {
    this.el = document.querySelector('.s-collection');

    this.container = this.el.querySelector('.container');

    const options = {
      mouseMultiplier: 0.8,
    }

    this.vs = new VirtualScroll(options);

    this.last = 0;
    this.ease = 0.1;
    this.current = 0;

    this.button = {
      el: this.el.querySelector('.button-scroll'),
      visible: true
    }
  }

  mounted() {
    if (Sniffer.isDevice) return;

    this.onResize();
    this.addEvents();

    gsap.ticker.add(this.onRaf);

    this.vs.on(this.onScroll);

    emitter.emit('scroll: destroyed');
  }

  destroyed = () => {
    if (Sniffer.isDevice) return;

    gsap.ticker.remove(this.onRaf);

    this.vs.destroy();
  }

  addEvents = () => {
    window.addEventListener('resize', this.onResize, { passive: true });
    this.button.el.addEventListener('click', this.onScrollTo);
  }

  removeEvents = () => {
    window.removeEventListener('resize', this.onResize, { passive: true });
    this.button.el.removeEventListener('click', this.onScrollTo);
  }

  setButtonVisibility = () => {
    if (this.current < 0) {
      if (this.button.visible) {
        gsap.to(this.button.el, { autoAlpha: 0 });
        this.button.visible = false;
      }
    } else {
      if (!this.button.visible) {
        gsap.to(this.button.el, { autoAlpha: 1 });
        this.button.visible = true;
      }
    }
  }

  onScrollTo = () => {
    this.current = -this.windowWidth;
  }

  onResize = () => {
    this.windowWidth = window.innerWidth;

    this.bounding = this.container.getBoundingClientRect();
  }

  onScroll = (e) => {
    this.current += e.deltaY;
    this.current = Math.max((this.bounding.width - this.windowWidth) * -1, this.current);
    this.current = Math.min(0, this.current);
  }

  onRaf = () => {
    if (-this.last < 0.1) this.last = 0;

    this.last = lerp(this.last, this.current, this.ease);

    this.container.style.transform = `translate3d(${this.last}px, 0px, 0px)`;

    this.setButtonVisibility();
  }
}
