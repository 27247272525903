export default class Surface {
  constructor() {
    this.taxonomies = [];
  }

  mounted() {
    async function fetchTaxonomies() {
      try {
        const response = await fetch('/wp-json/wp/v2/surface?per_page=100&lang=en&acf_format=standard');

        const taxonomies = await response.json();

        return taxonomies;
      } catch (e) { }
    }

    fetchTaxonomies().then(taxonomies => {
      this.taxonomies = taxonomies;
    });
  }
}
