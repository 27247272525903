import { gsap } from 'gsap';
import Sniffer from 'sniffer';

import { app } from '../../utils/store';

export default class Surfaces {
  constructor() {
    this.el = document.querySelector('.s-surfaces');

    this.media = this.el.querySelector('.media');
    this.link = this.el.querySelectorAll('.link');
    this.surface = this.el.querySelectorAll('.surface');

    this.mood = this.media.querySelector('.mood');
    this.structure = this.media.querySelector('.structure');

    this.layout = 1;
  }

  mounted() {
    this.init();

    if (Sniffer.isDevice) return;

    this.link.forEach((link, i) => {
      link.addEventListener('mouseenter', () => {
        this.setLayout(i);
      });
    });
  }

  destroyed = () => { }

  init = () => {
    if (!app.decor.posts.length || !app.surface.taxonomies.length) {
      setTimeout(() => {
        this.init();
      }, 100);
    } else {
      if (Sniffer.isPhone) {
        this.setLayoutPhone();
      } else {
        this.setLayout(0);
      }
    }
  }

  setLayoutPhone = () => {
    const surfaces = app.surface.taxonomies;

    surfaces.forEach((surface, i) => {
      const structure = surface.acf.surfaces[0].image.sizes['img-1024'];
      const img = `<img class="img" alt="" src="${structure}">`;

      this.surface[i].innerHTML = img;
    });
  }

  setLayout = (i) => {
    this.media.classList.remove(`layout-${this.layout}`);

    this.layout === 4 ? this.layout = 1 : this.layout++;

    this.media.classList.add(`layout-${this.layout}`);

    gsap.killTweensOf(this.media.children);

    gsap.fromTo(
      this.media.children,
      { autoAlpha: 0, yPercent: 20 },
      { autoAlpha: 1, yPercent: 0, stagger: 0.1, duration: 1.5, ease: 'expo.out' }
    );

    const surfaces = app.surface.taxonomies[i].acf.surfaces;

    const mood = `<img class="img" alt="" src="${surfaces[1].image.url}">`;
    const structure = `<img class="img" alt="" src="${surfaces[0].image.url}">`;

    this.mood.innerHTML = mood;
    this.structure.innerHTML = structure;
  }
}
