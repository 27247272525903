// styles
import './index.scss';

// store
import { app } from './utils/store';

// emitter
import * as emitter from 'tiny-emitter/instance';

// highway
import Highway from '@dogstudio/highway';

// sniffer
import Sniffer from 'sniffer';

// renderers
import Renderer from './renderers/renderer';

// transitions
import Default from './transitions/default';

// posts
import Decor from './utils/decor';

// taxonomies
import Surface from './utils/surface';

// ui
import Cursor from './components/cursor/cursor';
import Logo from './components/logo/logo';
import Menu from './components/menu/menu';
import Nav from './components/nav/nav';
import NavSurfaces from './components/nav-surfaces/nav-surfaces';
import Notification from './components/notification/notification';
import Zoom from './components/zoom/zoom';

// sniffer
Sniffer.addClasses(document.documentElement);

// init
app.decor = new Decor();
app.decor.mounted();

app.surface = new Surface();
app.surface.mounted();

app.cursor = new Cursor();
app.cursor.mounted();

app.logo = new Logo();
app.logo.mounted();

app.menu = new Menu();
app.menu.mounted();

app.nav = new Nav();
app.nav.mounted();

app.navSurfaces = new NavSurfaces();
app.navSurfaces.mounted();

app.notification = new Notification();
app.notification.mounted();

app.zoom = new Zoom();
app.zoom.mounted();

// core
app.highway = new Highway.Core({
  renderers: {
    renderer: Renderer
  },
  transitions: {
    default: Default,
  }
});

// update
app.highway.on('NAVIGATE_IN', ({ to, location }) => { });

app.highway.on('NAVIGATE_END', ({ to, from, trigger, location }) => {
  emitter.emit('logo: enter');
  emitter.emit('zoom: leave');
  
  emitter.emit('nav: enterEl');
  emitter.emit('nav: leaveFill');

  emitter.emit('navSurfaces: init');

  if (Sniffer.isDevice) return;

  emitter.emit('cursor: init');
});
